import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import ConversionTable from "../../components/ConversionTable.jsx";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Assembly - How it works"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`How it works`}</h1>
    <p><strong parentName="p">{`There are 4 different options to choose from when ordering your Pergola. Our basic 9ft x 9ft standard ‘building block’ system is designed to be extendable in a simple and easy way, proving that you do not need to be a construction engineer to install one!`}</strong></p>
    <p>{`The following sizes are available:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Option 1: Standard - 2700mm x 2700mm (@ 9ft x 9ft )`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Option 2: Standard - 2700mm x 4860mm (@ 9ft x 16ft )`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Option 3: Standard - 2700mm x 7020mm (@ 9ft x 23ft )`}</strong></li>
    </ol>
    <p>{`All our pergolas have a standard width of 9ft (2700mm) but with the option of incremental increases in length. We decided to keep to the 9ft (2700mm) width as our pergolas are designed as a ‘lean-to’ system and this width allows a quick and easy installation in most locations giving enough room in which to entertain, live in and customize to your own specification whilst keeping the costs down. To assemble any of the longer pergolas simply repeat the assembly instructions for each of the modular ‘building blocks’ – it couldn’t be easier!`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol"><strong parentName="li">{`Option 4: Customise the length to your own requirements`}</strong></li>
    </ol>
    <p>{`Due to the differences in heights (particularly in old buildings) of windows, doors and sills etc - we have built in added flexibility to the post lengths to give you the choice when our standard 2.4mtr length is not enough. Therefore, at no extra cost, you can specify post lengths from between 2.4mtrs (approx: 7ft 8ins) to 2.7mtrs (approx: 8ft 8ins) inclusive to meet your requirements. If you would like to take up this service, please email us at `}<a parentName="p" {...{
        "href": "mailto:info@perfectpergolas.co.uk"
      }}>{`info@perfectpergolas.co.uk`}</a>{` when you order your pergola and we will pass on your instructions to our Mill.`}</p>
    <p>{`If you require a custom made pergola, please visit our Pergola Planner facility accessed via the Homepage `}{`*`}{`NB - when designing your pergola or arbour, care must be taken to ensure that the the gap between the posts is no more then 3500mm for the 24hr high pressure treated timber and 4000mm for oak.`}</p>
    <p>{`Most of the measurements detailed in the following assembly drawings (Steps 1 - 8) are metric – however, if you prefer to work in good old 'feet & inches', please find below a simple conversion table allowing you to chose whatever system you find easiest:`}</p>
    <ConversionTable mdxType="ConversionTable" />
    <h4>{`Modules Parts List:`}</h4>
    <p>{`Please see below a drawing that shows all the constituent items that are needed in order to successfully build your pergola. Once you have chosen the length of pergola you require, just tick the option on our ordering page and leave the rest to us. All of the necessary components will be automatically calculated and added to your order – making it easier for you to concentrate on just the assembly.`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/how-it-works/fig-1.1B.jpg" alt="A schematic of Module 1 with annotated sections; 4 x rafters, 2 x posts, 1 x ledger board, 4 x struts, 1 x main beam" />
  <span className="text-metadata">Fig 1</span>
    </div>
    <p>{`The photograph below shows all of the hardware we will supply (depending on which module you purchase) in order to assemble the pergola in a safe and secure manner. Some of the parts are purely representative as occasionally different manufacturer’s components may be used if sourcing becomes difficult or is delayed in any way.`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/how-it-works/parts-new-screws.jpg" alt="A photographic list of the parts which comes with a pergola for construction purposes; wood screws, masonry screws, wall plugs, concrete bolt, coach bolts/nuts, joist hangers, angle brackets, T-plate, washer spacers, and an option of bolt-down fixings, concrete fixings, or spike fixings with a driving-in tool" />
  <span className="text-metadata">Fig 2</span>
    </div>
    <h4>{`Parts List:`}</h4>
    <div className="captioned">
  <img src="/img/pergola-assembly/how-it-works/mod1.jpg" alt="A schematic of Module 1, which is 9ft x 9ft in size" />
  <span className="text-metadata">Fig 3</span>
    </div>
    <h4>{`Module 1 – 9ft x 9ft`}</h4>
    <ul>
      <li parentName="ul">{`1 x Ledger board (decorated both ends x 2700mm)`}</li>
      <li parentName="ul">{`1 x Main beam (decorated both ends x 2700mm and Pre-drilled both ends for coach bolts)`}</li>
      <li parentName="ul">{`2 x Posts (2400mm Notched and pre-drilled for coach bolts) - can be specified up to 2.7mtrs`}</li>
      <li parentName="ul">{`4 x Rafters (decorated one end x 2700mm)`}</li>
      <li parentName="ul">{`4 x Strengthening struts (Pre-drilled)`}</li>
      <li parentName="ul">{`4 x Wall plugs`}</li>
      <li parentName="ul">{`4 x Masonry screws`}</li>
      <li parentName="ul">{`20 x Washer spacers`}</li>
      <li parentName="ul">{`4 x Mini joist hangers`}</li>
      <li parentName="ul">{`72 x Wood screws`}</li>
      <li parentName="ul">{`8 x Metal angle brackets`}</li>
      <li parentName="ul">{`2 x Coach bolts`}</li>
      <li parentName="ul">{`2 x Coach nuts`}</li>
      <li parentName="ul">{`2 x Coach bolt washers`}</li>
      <li parentName="ul">{`2 x Metal Fixing posts supports (choice of 3 different options – if using the ‘bolt-down’ system bolts will be provided)`}</li>
    </ul>
    <div className="captioned">
  <img src="/img/pergola-assembly/how-it-works/mod2.jpg" alt="A schematic of Module 2, which is 9ft x 16ft in size" />
  <span className="text-metadata">Fig 4</span>
    </div>
    <h4>{`Module 2 – 9ft x 16ft`}</h4>
    <ul>
      <li parentName="ul">{`2 x Ledger boards (decorated one end x 2430mm)`}</li>
      <li parentName="ul">{`2 x Main beams (decorated one end x 2430 and Pre-drilled one end for coach bolts)`}</li>
      <li parentName="ul">{`3 x Posts (2 x 2400mm Notched and Pre-drilled for coach bolts) & (1 x 2400mm Notched) - can be specified up to 2.7mtrs`}</li>
      <li parentName="ul">{`7 x Rafters`}</li>
      <li parentName="ul">{`7 x Strengthening struts (Pre-drilled)`}</li>
      <li parentName="ul">{`7 x wall plugs`}</li>
      <li parentName="ul">{`7 x masonry screws`}</li>
      <li parentName="ul">{`35 x Washer spacers`}</li>
      <li parentName="ul">{`7 x Mini joist hangers`}</li>
      <li parentName="ul">{`126 x Wood screws`}</li>
      <li parentName="ul">{`14 x Metal angle braces`}</li>
      <li parentName="ul">{`2 x Coach bolts`}</li>
      <li parentName="ul">{`2 x Coach nuts`}</li>
      <li parentName="ul">{`2 x Coach bolt washers`}</li>
      <li parentName="ul">{`1 x ‘T’ connector & 8 x wood screws`}</li>
      <li parentName="ul">{`3 x Metal Fixing post supports (choice of 3 different options – if using the ‘bolt-down’ system bolts will be provided`}</li>
    </ul>
    <div className="captioned">
  <img src="/img/pergola-assembly/how-it-works/mod3.jpg" alt="A schematic of Module 3, which is 9ft x 23ft in size" />
  <span className="text-metadata">Fig 5</span>
    </div>
    <h4>{`Module 3 – 9ft x 23ft`}</h4>
    <ul>
      <li parentName="ul">{`3 x Ledger boards (2 x decorated end 2430mm) & (1 x straight ended middle extension board 2160mm)`}</li>
      <li parentName="ul">{`3 x Main beams (2 x decorated end 2430mm and Pre-drilled one end for coach bolts) & (1 x straight ended middle extension beam 2160mm)`}</li>
      <li parentName="ul">{`4 x Posts (2 x 2400mm Notched and Pre-drilled for coach bolts) & (2 x 2400mm Notched) - can be specified up to 2.7mtrs`}</li>
      <li parentName="ul">{`10 x Rafters`}</li>
      <li parentName="ul">{`10 x Strengthening struts (Pre-drilled)`}</li>
      <li parentName="ul">{`10 x Wall plugs`}</li>
      <li parentName="ul">{`10 x masonry screws`}</li>
      <li parentName="ul">{`50 x washer spacers`}</li>
      <li parentName="ul">{`10 x Mini joist hangers`}</li>
      <li parentName="ul">{`180 x Wood screws`}</li>
      <li parentName="ul">{`20 x Metal angle brackets`}</li>
      <li parentName="ul">{`2 x Coach bolts`}</li>
      <li parentName="ul">{`2 x Coach nuts`}</li>
      <li parentName="ul">{`2 x Coach bolt washers`}</li>
      <li parentName="ul">{`2 x ‘T’ connector & 16 x wood screws`}</li>
      <li parentName="ul">{`4 x Metal Fixing post supports (choice of 3 different options – if using ‘bolt-down’ system bolts will be provided)`}</li>
    </ul>
    <p>{`Once you have received your delivery, please carefully check that all items have been included against the packing list supplied and, once done, you’re ready to start to assemble your own pergola. Please follow these simple steps as a guideline to ensure that the assembly of your pergola is as trouble free as possible:`}</p>
    <p><strong parentName="p">{`*`}{`NB – By using wood as the main material in the construction of a pergola, its very nature means you may find that it expands or contracts slightly depending on conditions. For example, if you experience difficulty in inserting a 100mm x 100mm post into the Fixing Post support - it may be because the wood has expanded a little. If this is the case, just take some coarse sandpaper and sand the bottom outside faces and edges that will be inserted into the support. Not too much though and it’s worth checking about every 30 seconds of sanding as to whether it will fit snuggly into the metal support. If not, continue to gently sand and check often!`}</strong></p>
    <p><em parentName="p">{`(`}{`*`}{`NB these drawings are for representation purposes only and not to scale)`}</em></p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }, {
      filename: "Module 1 - assembly guide",
      path: "/downloads/PP1-assembly.pdf"
    }, {
      filename: "Module 2 - assembly guide",
      path: "/downloads/PP2-assembly.pdf"
    }, {
      filename: "Module 3 - assembly guide",
      path: "/downloads/PP3-assembly.pdf"
    }, {
      filename: "Module 4 - assembly guide",
      path: "/downloads/PP4-assembly.pdf"
    }, {
      filename: "Module 5 - assembly guide",
      path: "/downloads/PP5-assembly.pdf"
    }, {
      filename: "Module 6 - assembly guide",
      path: "/downloads/PP6-assembly.pdf"
    }, {
      filename: "Module 7 - assembly guide",
      path: "/downloads/PP7-assembly.pdf"
    }, {
      filename: "Module 8 - assembly guide",
      path: "/downloads/PP8-assembly.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      